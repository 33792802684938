<script setup lang="ts">
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useQuery} from "@vue/apollo-composable";
import Spinner from "../Spinner.vue";
import CardList from "./List.vue";
import CardAdd from "./Add.vue";
import CheckoutCards from "../../graphql/query/CheckoutCards.gql";

const route = useRoute();

const props = defineProps<{
  data: string,
  value: number,
}>()

const emit = defineEmits<{
  (e: 'result', value: string): void
}>()

onMounted(() => {
  if (empty) {
    refetch()
  }
})

const showCardForm = ref(false)
const hideAddButton = ref(false)
const empty = ref(false)

const {result, loading, error, refetch, onResult} = useQuery(CheckoutCards, {
  data: route.params.data
}, {
  errorPolicy: 'all',
})

onResult((result) => {
  if (result?.data?.checkoutCards?.length <= 0) {
    showCardForm.value = true
    hideAddButton.value = true
    empty.value = true
  }
})

function toggleAddButton(value: boolean) {
  hideAddButton.value = value
}

function toggleForm(value: boolean) {
  showCardForm.value = value
  hideAddButton.value = value
}

function paymentResult(e: string) {
  emit('result', e)
}

function refresh(e: boolean) {
  if (e) {
    showCardForm.value = false
    hideAddButton.value = false
    refetch()
  }
}
</script>

<template>
  <div v-if="loading" class="flex h-screen -translate-y-32">
    <div class="m-auto">
      <Spinner></Spinner>
    </div>
  </div>

  <div v-else-if="error">
    {{ error }}
  </div>

  <div v-else class="p-4 h-full">
    <CardList v-if="!showCardForm" @toggle-add-button="toggleAddButton" :list="result.checkoutCards" @refresh="refresh" @result="paymentResult" :value="value"></CardList>
    <CardAdd v-else @toggle-form="toggleForm" @refresh="refresh" @result="paymentResult" :show-form="showCardForm" :value="value"></CardAdd>

    <div v-if="!hideAddButton" class="text-center uppercase bg-crimson-500 text-white p-2 rounded rounded-md cursor-pointer" @click="toggleForm(true)">
      Add New Card
    </div>
  </div>
</template>