<script setup lang="ts">
import {ref} from "vue";
import PayJustNowCheckout from "./Checkout.vue";
import PayJustNowAddress from "./Address.vue";

const props = defineProps<{
  data: string,
}>()

const emit = defineEmits<{
  (e: 'refresh', value: boolean): void
}>()

const data = JSON.parse(props.data)
const address = ref(data.address)

function addressUpdated(e: boolean) {
  address.value = e

  refresh(true)
}

function refresh(value: boolean) {
  emit('refresh', value)
}
</script>

<template>
  <PayJustNowCheckout v-if="address" @refresh="refresh"></PayJustNowCheckout>
  <PayJustNowAddress v-else @updated="addressUpdated"></PayJustNowAddress>
</template>