<script setup lang="ts">
import {computed, onMounted} from "vue";
import moment from "moment";
import {useMutation} from "@vue/apollo-composable";
import AllocationPreviewAccept from "../../graphql/mutation/AllocationPreviewAccept.gql";

const props = defineProps<{
  allocation: any
  id: string
  timestamp: any
  signature: any
  autoAccept: boolean
}>()

const emit = defineEmits<{
  (e: 'action', value: string): void
  (e: 'error', value: string): void
}>()

const timestamp = Math.floor(Date.now() / 1000)
const countdown = props.timestamp - timestamp

const formattedCountdown = computed(() => {
  return moment.utc(moment.duration(countdown, "seconds").asMilliseconds()).format('HH:mm:ss');
})

const {mutate: acceptAllocation, onDone} = useMutation(AllocationPreviewAccept, {
  errorPolicy: "all"
})

function accept() {
  acceptAllocation({
    id: props.id,
    timestamp: props.timestamp,
    signature: props.signature
  })
}

function dispute() {
  emit('action', 'dispute')
}

onMounted(() => {
  if (props.autoAccept) {
    accept()
  }
})

onDone((result) => {
  if (result.data.allocationPreviewAccept == true) {
    emit('action', 'accept')
  }

  if (result.errors) {
    emit('error', result.errors[0].message)
  }
})

</script>
<template>
  <div>
    <div class="mb-8">
      <h2 class="text-md font-bold mb-1">Description:</h2>
      <p class="my-4 whitespace-pre-line">{{ allocation.description }}</p>
      <p class="my-4"><span class="font-bold">Time Remaining for Acceptance:</span>
        {{ formattedCountdown || "Expired" }}</p>
    </div>

    <div class="flex text-center text-lg my-4">
      <div class="flex-1">
        <a
            class="bg-gray-600 hover:bg-gray-800 text-white font-bold items-center px-4 py-2 inline-flex rounded-md"
            @click.prevent="dispute" href="#">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mr-2" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
          </svg>
          <span>Decline</span>
        </a>
      </div>
      <div class="flex-1">
        <button
            class="bg-crimson-600 hover:bg-crimson-800 text-white font-bold items-center px-4 py-2 inline-flex rounded-md"
            @click.prevent="accept">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mr-2 " fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
          </svg>
          <span>Accept</span>
        </button>
      </div>
    </div>
  </div>
</template>
