<script setup lang="ts">
import {onMounted, ref} from "vue";
import {useQuery} from "@vue/apollo-composable";
import {useCheckoutStore} from '../../stores/checkout'
import Spinner from "../Spinner.vue";
import CheckoutLinkPJN from "../../graphql/query/CheckoutLinkPJN.gql";
import PayJustNowPending from "./Pending.vue";

const store = useCheckoutStore()
const url = ref();

const emit = defineEmits<{
  (e: 'refresh', value: boolean): void
}>()

const {result, loading, error, refetch, onResult} = useQuery(CheckoutLinkPJN, {
  data: store.data,
}, {
  errorPolicy: 'all',
})

onMounted(() => {
  refetch()
})

onResult((result) => {
  url.value = result?.data.checkoutLinkPJN

  window.open(result?.data.checkoutLinkPJN, '_blank');
})

function refresh(value: boolean) {
  emit('refresh', value)
}
</script>

<template>
  <div v-if="loading" class="flex h-screen -translate-y-32">
    <div class="m-auto">
      <Spinner></Spinner>
    </div>
  </div>

  <PayJustNowPending v-if="url" :link="url" class="w-full h-full" @refresh="refresh"></PayJustNowPending>
</template>
