<script setup lang="ts">
import {onUpdated, ref} from "vue";

const props = defineProps<{
  url: string
  payload: string
  term_url: string
  merchant_data: string
  timestamp: string
}>()

const secure3d = ref()

onUpdated(() => {
  if (props.url) {
    secure3d.value.submit()
  }
})

</script>

<template>
  <div v-if="url" class="w-full h-5/6">
    <div class="absolute top-0 right-0 bottom-0 left-0 z-40 bg-neutral-800 bg-opacity-70 p-4 backdrop-blur-sm">
      <iframe name="acs" class="w-full h-full p-4 mx-auto bg-white rounded-md"></iframe>
    </div>

    <form name="secure3d" ref="secure3d" method="post" target="acs" :action="url">
      <input type=hidden name="PaReq" :value="payload">
      <input type=hidden name="TermUrl" :value="term_url">
      <input type=hidden name="MD" :value="merchant_data">
    </form>
  </div>
</template>