<script setup lang="ts">
import {ref} from "vue";
import Spinner from "../Spinner.vue";

const props = defineProps<{
  data: string,
}>()

const emit = defineEmits<{
  (e: 'result', value: string): void
}>()

const data = JSON.parse(props.data)
const loading = ref(true)

function loaded() {
  loading.value = false
}

</script>

<template>
  <div v-if="loading" class="flex h-screen -translate-y-32">
    <div class="m-auto">
      <Spinner></Spinner>
    </div>
  </div>

  <div class="w-full h-full text-center p-4 text-lg md:w-full md:m-auto flex flex-col gap-8">
    <img class="w-48 h-48 mx-auto" :src="data.qrCode" alt="SnapScan QR Code" @load="loaded"/>

    <h3 class="text-gray-500 text-2xl">Scan here to pay</h3>

    <div class="mx-auto">
      <div class="flex gap-4 items-center text-sm max-w-md mb-8" v-if="loading === false">
        <div class="flex-1">
          Once you have made payment, click this button to confirm your payment.
        </div>

        <div class="flex-initial">
          <button type="button"
                  class="text-center bg-crimson-500 text-white p-2 cursor-pointer rounded rounded-md w-40"
                  @click="emit('result', 'success')">I Made Payment
          </button>
        </div>
      </div>

      <div class="flex gap-4 items-center text-sm max-w-md border-t pt-8">
        <div class="flex-1">
          Using a mobile device? Complete the payment in SnapScan.
        </div>

        <div class="flex-initial">
          <a :href="data.qrCode.replace('.svg', '')" target="_blank"
             class="p-2 inline-block text-white rounded rounded-md snapscan w-40">Open SnapScan App</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.snapscan {
  background-color: #4a90e2;
}
</style>
