<script setup lang="ts">
import {onMounted, ref} from "vue";
import Spinner from "../Spinner.vue";

const props = defineProps<{
  data: string,
}>()

const emit = defineEmits<{
  (e: 'result', value: string): void
}>()

const loading = ref(true)
let data = JSON.parse(props.data)

const convertLinks = (e: any) => {
  let r = '/payment/iframeredirect?redirecturl='

  return e.CancelUrl && e.CancelUrl.length > 0 && (e.CancelUrl = r + encodeURI(e.CancelUrl)), e.ErrorUrl && e.ErrorUrl.length > 0 && (e.ErrorUrl = r + encodeURI(e.ErrorUrl)), e.SuccessUrl && e.SuccessUrl.length > 0 && (e.SuccessUrl = r + encodeURI(e.SuccessUrl)), e
}

data = convertLinks(data)

let url =
    'https://pay.ozow.com/' +
    '?viewName=JsInjection&' +
    Object.keys(data)
        .map(function (option) {
          return encodeURIComponent(option) + '=' + encodeURIComponent(data[option])
        })
        .join('&')

function loaded() {
  loading.value = false
}

onMounted(() => {
  window.addEventListener('message', (e: any) => {
    if (e.origin === 'https://pay.ozow.com' && e.data.event === 'ipayMessage') {
      if (e.data.postData.Status === 'Complete') {
        emit('result', 'success');
      } else {
        emit('result', 'failure');
      }
    }
  })
})

</script>

<template>
  <div v-if="loading" class="flex h-screen -translate-y-32">
    <div class="m-auto">
      <Spinner></Spinner>
    </div>
  </div>

  <iframe :src="url" class="w-full h-full iframe" @load="loaded"></iframe>
</template>

<style>
.iframe {
  min-height: 60vh;
}
</style>
