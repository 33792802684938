import {createRouter, createWebHistory} from 'vue-router'
import Checkout from '/src/components/CheckoutPage.vue'
import Redirect from '/src/components/RedirectPage.vue'
import Accept from '/src/components/AcceptPage.vue'
import PageNotFound from '/src/components/PageNotFound.vue'

const routes = [
    {
        path: '/checkout/:data/:method?',
        name: 'Checkout',
        component: Checkout,
        props: true,
    },
    {
        path: '/checkout/embed/:data/:method?',
        name: 'Embedded Checkout',
        component: Checkout,
        props: true
    },
    {
        path: '/redirect/:data',
        name: 'Redirect',
        component: Redirect,
        props: true
    },
    {
        path: '/accept/:id',
        name: 'Accept Delivery',
        component: Accept,
        props: true
    },
    {
        path: "/:pathMatch(.*)*",
        component: PageNotFound
    },
    {
        path: "/:pathMatch(.*)",
        component: PageNotFound
    }
]
const router = createRouter({
    history: createWebHistory(),
    routes,
})
export default router