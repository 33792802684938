<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

defineProps<{
  gateways: any,
  embedded: boolean
}>()

const emit = defineEmits<{
  (e: 'result', value: string): void
}>()

const gatewayNames = (index: any) => {
  switch (index) {
    case 'eft':
      return "EFT"

    case 'instantEft':
      return "Ozow"

    case 'card':
      return "Debit/Credit Card"

    case 'ucount':
      return "UCount Rewards"

    case 'snapscan':
      return "SnapScan"

    case 'pjn':
      return "PayJustNow"

    case 'wallet':
      return "TradeSafe Wallet"

    default:
      return ''
  }
}

function imageUrl(type: string) {
  return new URL(`/src/assets/${type}.svg`, import.meta.url).href
}

const route = computed(() => {
  return useRoute().path
})
</script>

<template>
  <div class="h-full">
    <h3 class="m-5 my-6 text-xl">How Do You Want To Pay?</h3>
    <hr />
    <div class="pb-4">
      <div v-for="(gateway, index) in gateways">
        <router-link :to="route + '/' + index.toString().toLowerCase()" v-if="typeof gateway?.data != 'undefined'"
          class="px-6 py-2 h-20 border-b border-neutral-200 hover:shadow-md font-bold from-white to-neutral-100 hover:to-white flex items-center gap-2" :class="index.toString() === 'wallet' ? 'hidden' : ''">
          <div class="flex-1 m-auto">
            {{ gatewayNames(index) }}
            <div v-if="index.toString() === 'wallet'" class="text-xs text-gray-500 font-medium">
              Funds Available: {{ gateway.data.balance.toLocaleString('en-ZA', {style: 'currency', currency: 'ZAR'}).replace(',', '.') }}
            </div>
          </div>
          <div class="flex-initial w-32 my-auto content-end">
            <img :src="imageUrl(index.toString())" :alt="gatewayNames(index)" class="object-contain w-32 max-h-14" />
          </div>
        </router-link>
      </div>
      <div class="w-full content-center" v-if="!embedded">
        <div
          class="mx-auto mt-6 cursor-pointer border border-neutral-300 hover:bg-neutral-200 text-neutral-600 rounded rounded-md p-2 w-20 text-center"
          @click="emit('result', 'canceled')">Cancel</div>
      </div>

    </div>
  </div>
</template>
