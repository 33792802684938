<script setup lang="ts">
import {onMounted, Ref, ref, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useQuery} from '@vue/apollo-composable'
import {useCheckoutStore} from "../stores/checkout";
import Header from './Header.vue'
import Footer from './Footer.vue'
import PaymentOptions from './PaymentOptions.vue'
import Spinner from './Spinner.vue';
import Details from './Details.vue';
import Eft from './Eft/Index.vue'
import InstantEft from './InstantEft/Index.vue'
import Card from './Card/Index.vue'
import UCount from './UCount/Index.vue'
import SnapScan from './SnapScan/Index.vue'
import PayJustNow from './PayJustNow/Index.vue'
import PageNotFound from './PageNotFound.vue'
import CheckoutDetails from '../graphql/query/CheckoutDetails.gql'

const store = useCheckoutStore()
const route = useRoute()
const router = useRouter();

const loading = ref(true);

const props = defineProps<{
  data: string,
  method: string,
}>()

store.setData(props.data)

let messageData: any = {
  message: '',
  status: '',
  reference: '',
  transactionId: '',
  method: ''
};

const counter = ref();
const url = ref('');
const countdown = ref(10)
const embedded = ref(route.name === 'Embedded Checkout')
const complete = ref(false)
const transactionTotal = ref(0)
const embeddedResponse = ref({
  message: 'Payment successful',
  status: 'success',
  reference: 'reference',
  transactionId: 'id',
  method: 'eft',
})

const depositValue = ref(0)
const escrowFee = ref(0)

onMounted(() => {
  window.addEventListener('message', (e: any) => {
    if (e.origin === import.meta.env.VITE_PAYMENT_PAGE_URL && e.data.type === 'tradesafePaymentNotification') {
      transactionResult(e.data.status, e.data.message)
    }
  })

  if (props.method === 'wallet') {
    transactionResult('success')
  }
})

const {error, refetch, onResult} = useQuery(CheckoutDetails, {
  data: store.data,
}, {
  errorPolicy: 'all',
})

onResult((result) => {
  store.setDetails(result.data.checkoutDetails)
  embedded.value = result.data.checkoutDetails.embed === true
  loading.value = false
  depositValue.value = result.data.checkoutDetails.pendingDepositValue
  escrowFee.value = result.data.checkoutDetails.escrowFee
})

function updateTransactionTotal(value: number) {
  transactionTotal.value = value
}

function refreshDetails() {
  refetch()
}

function transactionResult(value: string, message: string = '') {
  let id = store?.details?.id
  let reference = store?.details?.reference

  switch (value.toLowerCase()) {
    case 'success':
      messageData = {
        message: 'Payment successful',
        status: 'success',
        reference: reference,
        transactionId: id,
        method: props.method,
      }
      break;
    case 'failure':
      if (message === '') {
        message = 'A problem occurred while processing your payment'
      }

      messageData = {
        message: message,
        status: 'error',
        reference: reference,
        transactionId: id,
        method: props.method,
      }
      break;
    case 'canceled':
      messageData = {
        message: 'Payment cancelled by user',
        status: 'canceled',
        reference: reference,
        transactionId: id,
        method: props.method
      }
      break;
  }

  embeddedResponse.value = messageData
  complete.value = true

  if (embedded.value) {
    if (!!messageData) {
      parent.postMessage({
        tradesafePaymentResponse: messageData
      }, '*')
    }
  } else {
    switch (value.toLowerCase()) {
      case 'success':
        url.value = store.details.redirects?.success + '?status=success' + '&method=' + props.method + '&transactionId=' + id + '&reference=' + reference
        break;
      case 'failure':
        url.value = store.details.redirects?.failure + '?status=failure&reason=error&transactionId=' + id + '&reference=' + reference
        break;
      case 'canceled':
        url.value = store.details.redirects?.failure + '?status=failure&reason=canceled&transactionId=' + id + '&reference=' + reference
        break;
    }

    startCountDown(url)
    complete.value = true

    // if (url !== null) {
    //   loading.value = true
    //
    //   setTimeout(function (url) {
    //     window.location.href = url
    //   }, 5000, url);
    // }
  }
}

store.$subscribe((mutation, state) => {
  switch (state.details.state) {
    case 'FUNDS_DEPOSITED':
    case 'FUNDS_RECEIVED':
    case 'INITIATED':
    case 'GOODS_ACCEPTED':
    case 'DELIVERED':
    case 'FUNDS_RELEASED':
    case 'COMPLETE':
      transactionResult('success')
      break
    case 'CANCELED':
    case 'DECLINED':
    case 'ADMIN_CANCELED':
      transactionResult('canceled')
      break
    case 'DISPUTED':
    case 'LEGAL':
    case 'ADMIN_SUSPENDED':
      transactionResult('failure')
      break
  }
})

function startCountDown(url: Ref<string>) {
  counter.value = setInterval(() => {
    countdown.value -= 1

    if (countdown.value <= 0) {
      clearInterval(counter.value);
      window.location.href = url.value
    }
  }, 1000)
}

function resetPage() {
  complete.value = false;
  countdown.value = 10;
  clearInterval(counter.value);
}

function formatValue(gateway: string) {
  let method = gateway

  if (method === 'instanteft') {
    method = 'instantEft'
  }

  return store.details.gateways[method].value.toLocaleString('en-ZA', {
    style: 'currency',
    currency: 'ZAR'
  }).replace(',', '.')
}

watch(() => props.method, (value, previousValue) => {
  let method = props.method;

  if (method === 'instanteft') {
    method = 'instantEft';
  }

  if (method !== '') {
    depositValue.value = store.details.gateways[method].value
    escrowFee.value = store.details.gateways[method].fee
  } else {
    depositValue.value = store.details?.pendingDepositValue
    escrowFee.value = store.details?.escrowFee
  }

  if (value === 'wallet') {
    transactionResult('success')
  }
})
</script>

<template>
  <div class="h-screen w-full lg:bg-neutral-100 text-neutral-700">
    <div v-if="loading" class="flex h-screen lg:h-5/6">
      <div class="m-auto">
        <Spinner></Spinner>
      </div>
    </div>

    <div v-else-if="store.details !== null" class="w-full">
      <Header v-if="!embedded"
              :logo="store.details?.logo" name="Company Logo"
              :title="store.details?.title"
              :description="store.details?.description"
              :deposit="store.details?.depositValue"
              :total="store.details?.pendingDepositValue"
              :items="store.details?.items"
              :fee="escrowFee"
              :back="false"
      />

      <div v-if="complete && embeddedResponse" class="flex h-screen relative" id="complete" :class="embedded ? 'pt-4' : 'pt-16 lg:pt-20'">
        <div class="mx-auto h-5/6 text-center p-4 z-10">
          <div v-if="embeddedResponse.status === 'success'" class="flex flex-col gap-8 h-full">
            <div class="flex-1 flex flex-col justify-center">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                     class="m-auto w-64 h-64 text-green-600">
                  <path fill-rule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                        clip-rule="evenodd"/>
                </svg>

                <h3 class="text-green-600 font-bold text-4xl mb-8">Payment Successful</h3>
              </div>

              <div class="font-bold text-xl text-gray-600" v-if="embeddedResponse.method">We have received your payment
                of
                {{ formatValue(embeddedResponse.method) }}
              </div>
            </div>

            <div v-if="!embedded" class="">
              <a :href="url"
                 class="bg-green-600 rounded-2xl text-white p-4 block cursor-pointer max-w-xs m-auto">
                Return to Merchant
              </a>
              <div class="py-4">Automatic redirect in {{ countdown }} seconds</div>
            </div>
          </div>

          <div v-if="embeddedResponse.status === 'error'" class="flex flex-col gap-8 h-full">
            <div class="flex-1 flex flex-col justify-center">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="m-auto w-64 h-64 text-red-600">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                </svg>

                <h3 class="text-red-600 font-bold text-4xl mb-8">Payment Unsuccessful</h3>

                <div class="font-bold text-xl text-gray-600 mb-8">{{ embeddedResponse.message }}</div>

                <router-link
                    class="bg-yellow-500 rounded-2xl text-white p-4 block cursor-pointer max-w-xs m-auto capitalize"
                    :to="{ name: route.name ?? 'Checkout', params: { data: route.params.data, method: null }}"
                    v-on:click="resetPage">
                  Try a different payment method
                </router-link>
              </div>
            </div>

            <div v-if="!embedded" class="">
              <a :href="url"
                 class="bg-red-600 rounded-2xl text-white p-4 block cursor-pointer max-w-xs m-auto">
                Return to Merchant
              </a>
              <div class="py-4">Automatic redirect in {{ countdown }} seconds</div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="grid grid-cols-1 lg:grid-cols-2 lg:gap-4 lg:w-2/3 lg:mx-auto h-full"
           :class="embedded ? 'pt-4 pb-16' : 'pb-4 pt-16 lg:pt-20'">
        <div class="hidden lg:block p-4 bg-white rounded-xl">
          <img :src="store.details?.logo" class="mb-4">

          <Details :title="store.details?.title" :description="store.details?.description"
                   :items="store.details?.items" :deposit="store.details?.depositValue" :fee="escrowFee"
                   :total="depositValue"></Details>
        </div>

        <div class="bg-white rounded-xl h-full lg:overflow-hidden">
          <PaymentOptions v-if="method === ''" :gateways="store.details?.gateways" :embedded="embedded"
                          @result="transactionResult"></PaymentOptions>

          <div v-else class="flex items-center p-4">
            <div class="flex-1">
              <router-link class="flex items-center"
                           :to="{ name: route.name ?? 'Checkout', params: { data: route.params.data, method: null }}">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                     class="w-5 h-5 mr-1 inline-block">
                  <path fill-rule="evenodd"
                        d="M9.53 2.47a.75.75 0 010 1.06L4.81 8.25H15a6.75 6.75 0 010 13.5h-3a.75.75 0 010-1.5h3a5.25 5.25 0 100-10.5H4.81l4.72 4.72a.75.75 0 11-1.06 1.06l-6-6a.75.75 0 010-1.06l6-6a.75.75 0 011.06 0z"
                        clip-rule="evenodd"/>
                </svg>
                Back
              </router-link>
            </div>

            <div class="flex-none">
              <div class="flex items-center border border-neutral-500 py-1 px-2 rounded rounded-3xl text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                     class="w-4 h-4 inline-block">
                  <path fill-rule="evenodd"
                        d="M12.516 2.17a.75.75 0 00-1.032 0 11.209 11.209 0 01-7.877 3.08.75.75 0 00-.722.515A12.74 12.74 0 002.25 9.75c0 5.942 4.064 10.933 9.563 12.348a.749.749 0 00.374 0c5.499-1.415 9.563-6.406 9.563-12.348 0-1.39-.223-2.73-.635-3.985a.75.75 0 00-.722-.516l-.143.001c-2.996 0-5.717-1.17-7.734-3.08zm3.094 8.016a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                        clip-rule="evenodd"/>
                </svg>
                SSL/TLS Encrypted
              </div>
            </div>
          </div>

          <Eft v-if="method === 'eft' && store.details.gateways['eft']"
               :data="store.details?.gateways?.eft?.data"
               :value="store.details?.gateways?.eft?.value"
               @result="transactionResult"
          ></Eft>

          <InstantEft v-if="method === 'instanteft' && store.details.gateways['instantEft']"
                      :data="store.details?.gateways?.instantEft?.data"
                      @result="transactionResult"
          ></InstantEft>

          <Card v-if="method === 'card' && store.details.gateways['card']"
                :data="store.details?.gateways?.card?.data"
                :value="store.details?.gateways?.card?.value"
                @result="transactionResult"
          ></Card>

          <UCount v-if="method === 'ucount' && store.details.gateways['ucount']"
                  :data="store.details?.gateways?.card?.data"
                  :value="store.details?.gateways?.card?.value"
                  @result="transactionResult"
          ></UCount>

          <SnapScan v-if="method === 'snapscan' && store.details.gateways['snapscan']"
                    :data="store.details?.gateways?.snapscan?.data"
                    @result="transactionResult"
          ></SnapScan>

          <PayJustNow v-if="method === 'pjn' && store.details.gateways['pjn']"
                      :data="store.details?.gateways?.pjn?.data"
                      @refresh="refreshDetails()"
                      @result="transactionResult"
          ></PayJustNow>
        </div>
      </div>

      <Footer v-if="!embedded"/>
    </div>

    <page-not-found v-else></page-not-found>
  </div>
</template>

<style scoped>
#complete::before {
  background-image: url("/src/assets/soft-red-stripes-background-element-right.svg");
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  background-size: 70%;
  background-repeat: no-repeat;
  margin: auto;
  top: 0;
  background-position: 95% 25%;
  left: 0;
  z-index: 0;
  transform: scale(2);
}

#complete::after {
  background-image: url("/src/assets/soft-red-stripds-background-element-left.svg");
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  background-size: 70%;
  background-repeat: no-repeat;
  margin: auto;
  top: 0;
  background-position: 200% 25%;
  left: 0;
  z-index: 0;
  transform: scale(-2);
}

@media only screen and (min-width: 768px) {
  #complete::before {
    background-position: 115% -5%;
  }
  #complete::after {
    background-position: 200% -15%;
  }
}

@media only screen and (min-width: 1024px) {
  #complete::before {
    transform: scale(1.5);
    background-position: 145% -100%;
  }
  #complete::after {
    transform: scale(-1.5);
    background-position: 230% -80%;
  }
}

@media only screen and (min-width: 1280px) {
  #complete::before {
    transform: scale(1);
    background-position: 150% -700%;
  }
  #complete::after {
    transform: scale(-1);
    background-position: 280% -600%;
  }
}

@media only screen and (min-width: 1536px) {
  #complete::before {
    background-position: 145% 100%;
  }
  #complete::after {
    background-position: 280% 160%;
  }
}
</style>
