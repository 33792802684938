import {ref} from 'vue'
import {defineStore} from 'pinia'

export const useCheckoutStore = defineStore('checkout', () => {
    const data = ref()
    const details = ref()
    const production = import.meta.env.PROD

    function setData(value: string) {
        data.value = value
    }

    function setDetails(value: any) {
        if (!production) {
            // TODO: Remove once proper wallet is implemented
            value = JSON.parse(JSON.stringify(value));

            if (value) {
                value.gateways.wallet = {
                    data: {
                        'name': 'TradeSafe Wallet',
                        'balance': 12874.42
                    },
                    value: value.pendingDepositValue
                }
            }
        }

        details.value = value
    }

    return {data, details, setData, setDetails}
})
