<script setup lang="ts">
import {reactive, ref} from "vue";
import {useRoute} from "vue-router";
import {useMutation} from "@vue/apollo-composable";
import CheckoutCardDelete from "../../graphql/mutation/CheckoutCardDelete.gql";
import CheckoutCardProcess from "../../graphql/mutation/CheckoutCardProcess.gql";
import CheckoutCardAcs from "../../graphql/mutation/CheckoutCardAcs.graphql";
import CardFace from "./CardFace.vue";
import {numeric, required, minLength, maxLength} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import CardAcs from "./Acs.vue";

const route = useRoute();

const errorMessage = ref()
const acsUrl = ref('')
const acsPayload = ref('')
const merchantData = ref('')
const termUrl = import.meta.env.VITE_PAYMENT_PAGE_URL + '/api/ecentric/secure3d'
const timestamp = new Date().toTimeString()

defineProps<{
  value: number
  card: any,
}>()

const emit = defineEmits<{
  (e: 'result', value: string): void
  (e: 'refresh', value: boolean): void
}>()

const rules = {
  cvv: {required, numeric, minLength: minLength(3), maxLength: maxLength(3)},
}

const state = reactive({
  'cvv': null,
})

const v$ = useVuelidate(rules, state)

const {
  mutate: deleteCard,
  loading: deleteLoading,
  error: deleteError,
  onDone: deleteDone
} = useMutation(CheckoutCardDelete, {
  errorPolicy: "all"
})

const {
  mutate: processCard,
  loading: processLoading,
  error: processError,
  onDone: processDone
} = useMutation(CheckoutCardProcess, {
  errorPolicy: "all"
})

const {
  mutate: acsCard,
  loading: acsLoading,
  error: acsError,
  onDone: acsDone
} = useMutation(CheckoutCardAcs, {
  errorPolicy: "all"
})

processDone((result) => {
  if (result?.data?.checkoutCardProcess) {
    errorMessage.value = null;
    emit('result', 'success')
  } else {
    if (result?.errors) {
      errorMessage.value = result?.errors[0]?.extensions?.reason
    }
  }
})

deleteDone((result) => {
  if (result?.data?.checkoutCardDelete) {
    emit('refresh', true)
  }
})

function removeCard(card: any) {
  deleteCard({
    data: route.params.data,
    cardId: card.id
  })
}

function authorizeCard(card: any) {
  let variables = {
    data: route.params.data,
    cardId: card.id,
    cvv: state.cvv
  }

  if (state.cvv) {
    acsCard(variables)
  } else {
    processCard(variables)
  }
}

acsDone((result) => {
  if (result?.data?.checkoutCardAcs) {
    errorMessage.value = ''
    acsUrl.value = result?.data?.checkoutCardAcs?.acsUrl
    acsPayload.value = result?.data?.checkoutCardAcs?.requestPayload
    merchantData.value = result?.data?.checkoutCardAcs?.merchantData
  }
})
</script>

<template>
  <div v-if="card" class="">
    <div v-if="errorMessage"
         class="border border-red-600 bg-red-50 text-red-600 rounded rounded-xl text-center p-4 mb-4">{{ errorMessage }}
    </div>

    <card-face :card="card"></card-face>

    <div v-if="!processLoading && !deleteLoading "
         class="text-center uppercase bg-neutral-50 hover:bg-neutral-300 border border-neutral-400 text-neutral-800 p-2 rounded rounded-md cursor-pointer mb-6"
         @click="removeCard(card)">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
           class="w-5 h-5 inline-block">
        <path stroke-linecap="round" stroke-linejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
      </svg>
      Remove Card
    </div>

    <p v-if="card.active === false" class="text-sm">This card has not been used in the last 30 days. Please verify your
      CVV to complete payment.</p>

    <div class="col-span-1 relative" v-if="card.active === false">
      <label class="sr-only" for="cvv">CVV</label>
      <input name="cvv" type="text" minlength="3" maxlength="3" v-model="state.cvv" @blur="v$.cvv.$touch"
             placeholder="CVV"
             pattern="[0-9]{3}"
             class="w-full mt-2 mb-4 p-2 pl-8 rounded border border-neutral-300 focus:border-neutral-300 focus:ring-0 focus:outline-crimson-500">

      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
           class="absolute top-5 left-2 w-5 h-5 text-neutral-500">
        <path stroke-linecap="round" stroke-linejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
      </svg>

      <div class="text-crimson-500" v-for="error of v$.cvv.$errors" :key="error.$uid">
        <div class="error-msg text-sm">{{ error.$message }}</div>
      </div>
    </div>

    <button v-if="!processLoading && !deleteLoading"
            class="w-full col-span-3 text-center uppercase text-white p-2 rounded rounded-md"
            :class="v$.$error !== false || (state.cvv === null && card.active === false) ? 'bg-neutral-400' : 'bg-crimson-500 cursor-pointer'"
            @click="authorizeCard(card)"
            :disabled="v$.$error !== false || (state.cvv === null && card.active === false)">
      Pay {{ value.toLocaleString('en-ZA', {style: 'currency', currency: 'ZAR'}).replace(',', '.') }}
    </button>
  </div>

  <div v-if="processLoading || deleteLoading"
       class="w-full col-span-3 text-center uppercase bg-neutral-400 text-white p-2 rounded rounded-md cursor-progress">
    Processing...
  </div>

  <card-acs :payload="acsPayload" :url="acsUrl" :term_url="termUrl" :merchant_data="merchantData"
            :timestamp="timestamp"></card-acs>
</template>
