<script setup lang="ts">
import {ref} from "vue";
import {useRoute} from "vue-router";
import {useMutation} from "@vue/apollo-composable";
import Success from "./Success.vue"
import CheckoutEftProcess from '../../graphql/mutation/CheckoutEftProcess.gql'

const route = useRoute();

const props = defineProps<{
  data: string,
  value: number
}>()

const emit = defineEmits<{
  (e: 'total', value: number): void
  (e: 'result', value: string): void
}>()

const data = JSON.parse(props.data)
const success = ref(false)

const {mutate: initiateEft, loading, error, onDone} = useMutation(CheckoutEftProcess, {
  errorPolicy: "all"
})

async function initiate() {
  await initiateEft({
    data: route.params.data,
  })
}

function copyToClipboard(value: any) {
  navigator.clipboard.writeText(value)
  alert('Copied ' + value + ' to clipboard!')
}

onDone((result) => {
  if (result?.data?.checkoutEftProcess) {
    success.value = true
  }
})

function setResult(value: string) {
  emit('result', value)
}

emit('total', props.value)
</script>

<template>
  <success v-if="success" :value="value" @result="setResult"></success>

  <div v-else class="p-4 h-full md:max-w-3xl md:m-auto">
    <div class="grid grid-cols-2 gap-2">
      <div class="text-center text-sm p-2 border border-neutral-400 bg-neutral-100 rounded-md">
        <div>Amount</div>
        <div class="font-bold text-xl">{{ value.toLocaleString('en-ZA', {style: 'currency', currency: 'ZAR'}).replace(',', '.') }}</div>
      </div>

      <div class="relative text-center text-sm p-2 border border-crimson-500 bg-crimson-100 rounded-md">
        <div>Reference</div>
        <div class="font-bold text-xl">{{ data.reference }}</div>

        <div @click="copyToClipboard(data.reference)"
             class="cursor-pointer absolute bottom-3 right-2 text-neutral-500 hover:text-black inline-block float-right">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"/>
          </svg>
        </div>
      </div>

      <div class="col-span-2 text-sm border border-md p-2 rounded-md border-neutral-400">
        <div class="flex">
          <div class="flex-none">Account Name</div>
          <div class="flex-1 text-right font-bold">TradeSafe Escrow</div>
        </div>
        <div class="flex mt-2">
          <div class="flex-none">Bank</div>
          <div class="flex-1 text-right font-bold">{{ data.name }}</div>
        </div>
        <div class="flex mt-2">
          <div class="flex-none">Account Number</div>
          <div class="flex-1 text-right font-bold">{{ data.number }}</div>
        </div>
        <div class="flex mt-2">
          <div class="flex-none">Branch Code</div>
          <div class="flex-1 text-right font-bold">{{ data.branch }}</div>
        </div>
      </div>
    </div>

    <div class="mt-4 lg:mt-8 text-[0.8125rem] lg:text-sm text-center">
      <p class="mb-3 lg:mb-4">Go ahead and make payment via your internet banking. Please reference your payment with
        {{ data.reference }} so that we can automatically allocate the funds to this transaction.</p>
      <p class="mb-3 lg:mb-4">Please ensure that you enter the above bank account details correctly. TradeSafe will not be
        held
        responsible should the funds be paid into another account.</p>
      <p class="mb-3 lg:mb-4">Click the button below once you have made payment.</p>
    </div>

    <div class="">
      <div v-if="!loading" class="text-center uppercase bg-crimson-500 text-white p-2 rounded rounded-md cursor-pointer"
           @click="initiate">
        I Have Made Payment
      </div>

      <div v-if="loading" class="text-center uppercase bg-neutral-400 text-white p-2 cursor-pointer">
        Updating...
      </div>
    </div>
  </div>
</template>
