<script setup lang="ts">
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useQuery} from "@vue/apollo-composable";
import HeaderSimple from './HeaderSimple.vue'
import Footer from './Footer.vue'
import Spinner from "./Spinner.vue";
import Details from "./Accept/Details.vue";
import Success from "./Accept/Success.vue";
import Disputed from "./Accept/Disputed.vue";
import DisputeFrom from "./Accept/Form.vue";
import AllocationPreview from "../graphql/query/AllocationPreview.gql";

const props = defineProps<{
  id: string
}>()

const route = useRoute();
const page = ref('details')
const autoAccept = ref(false)
const errorMessage = ref('')

const {
  result, loading, error, onResult, onError
} = useQuery(AllocationPreview, {
  id: props.id,
  timestamp: route.query.timestamp,
  signature: route.query.signature
}, {
  errorPolicy: 'all',
})

onResult((result) => {
  if (result.data.allocationPreview?.state) {
    switch (result.data.allocationPreview.state) {
      case "DELIVERED":
      case "FUNDS_RELEASED":
        page.value = 'accept'
        break
      case "DISPUTED":
        page.value = 'dispute'
    }
  }
})

onError((error) => {
  displayError(error.message)
})

function action(result: string) {
  page.value = result
}

function displayError(result: string) {
  errorMessage.value = result
}

onMounted(() => {
  if (route.query.action) {
    switch (route.query.action) {
      case 'accept':
        autoAccept.value = true
        break
      case 'dispute':
        page.value = 'dispute'
        break
    }
  }
})

</script>
<template>
  <div class="h-screen w-full lg:bg-neutral-100 text-neutral-700 overflow-auto">
    <div v-if="loading" class="flex h-screen lg:h-5/6">
      <div class="m-auto">
        <Spinner></Spinner>
      </div>
    </div>

    <div v-else>
      <HeaderSimple/>

      <main class="w-11/12 md:w-7/12 shadow-md border mx-auto mt-4 mb-44 md:mt-8 p-4 md:p-8 rounded-xl">
        <div class="flex items-center border-b mb-4 ">
          <h1 class="w-8/12 text-2xl md:text-3xl font-bold">{{ result.allocationPreview.title }}</h1>
          <div class="w-4/12 text-right"><img :src="result.allocationPreview.logoUrl" v-if="result.allocationPreview.logoUrl" /></div>
        </div>

        <div v-if="errorMessage" class="border bg-red-100 border-red-600 text-red-800 py-2 px-4 mb-8 rounded">
          {{ errorMessage }}
        </div>

        <Details v-if="page === 'details'"
                 :allocation="result.allocationPreview"
                 :id="id"
                 :timestamp="route.query.timestamp"
                 :signature="route.query.signature"
                 :autoAccept="autoAccept"
                 @action="action"
                 @error="displayError"/>

        <DisputeFrom v-if="page === 'dispute'"
                     :allocation="result.allocationPreview"
                     :id="id"
                     :timestamp="route.query.timestamp"
                     :signature="route.query.signature"
                     @action="action"
                     @error="displayError"/>

        <Success v-if="page === 'accept'"
                 :allocation="result.allocationPreview"/>

        <Disputed v-if="page === 'disputed'"
                  :allocation="result.allocationPreview"/>
      </main>

      <Footer/>
    </div>
  </div>
</template>
