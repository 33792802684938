<script setup lang="ts">
import {ref} from "vue";
import {useRoute} from "vue-router";
import {useMutation} from "@vue/apollo-composable";
import CheckoutAddressUpdate from "../../graphql/mutation/CheckoutAddressUpdate.gql"

const route = useRoute();

const emit = defineEmits<{
  (e: 'updated', value: boolean): void
}>()

const {mutate: updateAddress, loading, error, onDone} = useMutation(CheckoutAddressUpdate, {
  errorPolicy: "all"
})

const line1 = ref('')
const line2 = ref('')
const city = ref('')
const area = ref('')
const code = ref('')

function submitAddress(e: any) {
  e.preventDefault()

  updateAddress({
    data: route.params.data,
    line1: line1.value,
    line2: line2.value,
    city: city.value,
    area: area.value,
    code: code.value,
  })

  onDone((result) => {
    if (result?.data?.checkoutAddressUpdate) {
      emit('updated', true)
    }
  })
}
</script>

<template>
  <div>
    <div class="px-4 py-8 text-sm text-center text-white bg-pjn">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 129 64" class="h-24 mb-8 m-auto">
        <path fill="#fff"
              d="M4.84 60.027H2.75v3.759H0V51.259h5.107c2.982 0 4.786 1.777 4.786 4.33v.036c0 2.902-2.25 4.402-5.054 4.402Zm2.267-4.384c0-1.232-.857-1.893-2.232-1.893H2.75v3.83h2.18c1.375 0 2.178-.821 2.178-1.892v-.045Zm12.876 8.134-1.143-2.813h-5.286l-1.143 2.813H9.607l5.358-12.616H17.5l5.358 12.616h-2.875Zm-3.786-9.295-1.661 4.063h3.322l-1.661-4.063Zm12.902 4.304v4.991h-2.75v-4.938l-4.804-7.58h3.214l2.983 5.027 3.035-5.027h3.125L29.1 58.786Zm8.536 5.179c-1.875 0-3.072-.93-3.858-2.224l1.018-.875c.768 1.16 1.554 1.786 2.858 1.786 1.41 0 2.446-1.018 2.446-3v-8.393h1.429v8.375c0 2.875-1.661 4.33-3.893 4.33Zm17.455-5.536c0 3.651-2.089 5.544-5.214 5.544-3.09 0-5.197-1.892-5.197-5.437V51.25h1.411v7.197c0 2.705 1.429 4.223 3.822 4.223 2.303 0 3.767-1.393 3.767-4.134V51.25h1.411v7.179Zm7.822-1.589c2.786.606 4.071 1.633 4.071 3.562 0 2.16-1.785 3.562-4.268 3.562-1.982 0-3.607-.66-5.107-2l.875-1.035c1.304 1.178 2.554 1.768 4.286 1.768 1.679 0 2.786-.875 2.786-2.143 0-1.179-.625-1.849-3.25-2.393-2.875-.625-4.197-1.554-4.197-3.634 0-2.01 1.732-3.438 4.107-3.438 1.822 0 3.125.518 4.393 1.536l-.821 1.09c-1.16-.947-2.322-1.358-3.607-1.358-1.625 0-2.661.893-2.661 2.045 0 1.187.643 1.848 3.393 2.437Zm11.411 6.937h-1.429V52.562h-4.196V51.26h9.821v1.303h-4.196v11.215Zm16.241 0-6.054-7.965v7.965h-2.723V51.259h2.536l5.857 7.714V51.26h2.714v12.518h-2.33Zm11.411.223c-3.857 0-6.625-2.875-6.625-6.446v-.036c0-3.563 2.803-6.473 6.661-6.473 3.857 0 6.625 2.875 6.625 6.437v.036c0 3.563-2.804 6.482-6.661 6.482Zm3.786-6.482c0-2.152-1.572-3.938-3.786-3.938s-3.75 1.75-3.75 3.902v.036c0 2.152 1.571 3.938 3.786 3.938 2.214 0 3.75-1.75 3.75-3.902v-.036Zm18.348 6.357h-2.393l-2.821-8.196-2.822 8.196h-2.392l-4.268-12.616h2.946l2.589 8.482 2.804-8.518h2.357l2.804 8.518 2.589-8.482h2.875l-4.268 12.616Z"/>
        <path fill="#FF5C75"
              d="m108.957 32.303-4.848 3.179c-6.375-9.768-16.161-16.465-27.563-18.858-11.402-2.393-23.054-.196-32.804 6.188-8.09 5.303-14.072 12.955-17.233 21.938h-6.107c3.375-11.01 10.429-20.411 20.17-26.795 11.045-7.233 24.242-9.724 37.162-7.01 12.91 2.706 24 10.287 31.223 21.358Z"/>
        <path fill="#009EDD"
              d="M79.751 1.285 78.555 6.99c-13.974-2.928-28.25-.24-40.197 7.59-10.893 7.134-18.67 17.75-22.17 30.17H10.16c3.66-14.465 12.464-26.84 25.01-35.054 13.25-8.68 29.08-11.67 44.58-8.411Z"/>
        <path fill="#6BE1E7"
              d="M32.983 6.347c-13.67 8.947-23.188 22.536-26.93 38.394H.108C3.973 26.857 14.51 11.508 29.813 1.49l3.17 4.856Z"/>
      </svg>
      <p class="mb-4">Buy Now Pay Later! Get your goods now by paying 3 equal interest-free instalments.</p>
      <p class="">Please provide your residential address to kick off the application. Please be truthful&#8230; or your
        application will be rejected.</p>
    </div>

    <form class="p-4 grid grid-cols-2 gap-2" @submit="submitAddress">
      <div class="col-span-2 relative">
        <label for="line1" class="sr-only">Address 1</label>
        <input name="line1" type="text" v-model="line1" required placeholder="Address Line 1"
               class="w-full mt-2 mb-4 p-2 pl-8 rounded border border-neutral-300 focus:border-neutral-300 focus:ring-0 focus:outline-crimson-500"/>

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="absolute top-5 left-2 w-5 h-5 text-neutral-500">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"/>
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"/>
        </svg>
      </div>

      <div class="col-span-2 relative">
        <label for="line2" class="sr-only">Address 2</label>
        <input name="line2" type="text" v-model="line2" required placeholder="Address Line 2"
               class="w-full mt-2 mb-4 p-2 pl-8 rounded border border-neutral-300 focus:border-neutral-300 focus:ring-0 focus:outline-crimson-500"/>

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="absolute top-5 left-2 w-5 h-5 text-neutral-500">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"/>
        </svg>
      </div>

      <div class="col-span-2 relative">
        <label for="city" class="sr-only">City</label>
        <input name="city" type="text" v-model="city" required placeholder="City"
               class="w-full mt-2 mb-4 p-2 pl-8 rounded border border-neutral-300 focus:border-neutral-300 focus:ring-0 focus:outline-crimson-500"/>

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="absolute top-5 left-2 w-5 h-5 text-neutral-500">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"/>
        </svg>
      </div>

      <div class="relative">
        <label for="area" class="sr-only">Province</label>
        <select name="area" v-model="area" required
                class="w-full mt-2 mb-4 p-2 pl-8 rounded border border-neutral-300 focus:border-neutral-300 focus:ring-0 focus:outline-crimson-500 invalid:text-neutral-500">
          <option value="" disabled selected>Province</option>
          <option value="Eastern Cape">Eastern Cape</option>
          <option value="Free State">Free State</option>
          <option value="Gauteng">Gauteng</option>
          <option value="KwaZulu-Natal">KwaZulu-Natal</option>
          <option value="Limpopo">Limpopo</option>
          <option value="Mpumalanga">Mpumalanga</option>
          <option value="Northern Cape">Northern Cape</option>
          <option value="North West">North West</option>
          <option value="Western Cape">Western Cape</option>
        </select>

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="absolute top-5 left-2 w-5 h-5 text-neutral-500">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"/>
        </svg>
      </div>

      <div class="relative">
        <label for="code" class="sr-only">Postal Code</label>
        <input name="code" type="text" v-model="code" required placeholder="Postal Code"
               class="w-full mt-2 mb-4 p-2 pl-8 rounded border border-neutral-300 focus:border-neutral-300 focus:ring-0 focus:outline-crimson-500"/>

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="absolute top-5 left-2 w-5 h-5 text-neutral-500">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5"/>
        </svg>
      </div>

      <div v-if="loading"
           class="w-full col-span-2 text-center uppercase bg-neutral-400 text-white p-2 rounded-md cursor-progress">
        Updating Address...
      </div>

      <button v-else type="submit"
              class="w-full col-span-2 text-center uppercase bg-crimson-500 text-white p-2 rounded-md cursor-pointer">
        Update Address
      </button>
    </form>
  </div>
</template>

<style>
.bg-pjn {
  background-color: #14204a;
}
</style>