<template>
  <div class="w-full">
    <div class="lg:col-span-2 p-2 lg:p-4 text-[0.6rem] lg:text-sm text-neutral-500 bg-neutral-100 text-center">
      By continuing, you agree to TradeSafe's
      <a href="https://www.tradesafe.co.za/documents/terms_and_conditions.pdf" target="_blank" class="border-b border-dashed border-b-neutral-800">Terms of Service</a> &amp;
      <a href="https://www.tradesafe.co.za/documents/privacy_policy.pdf" target="_blank" class="border-b border-dashed border-b-neutral-800">Privacy Policy</a>
    </div>

    <div class="text-xs bg-neutral-600 text-white p-2 lg:p-4 text-center">
      <img src="/src/assets/powered_by.svg" class="h-12 lg:h-16 w-auto m-auto"/>
    </div>
  </div>
</template>
