<script setup lang="ts">
import {ref} from "vue";
import {useMutation} from "@vue/apollo-composable";
import AllocationPreviewDispute from "../../graphql/mutation/AllocationPreviewDispute.gql";

const props = defineProps<{
  id: string
  timestamp: any
  signature: any
}>()

const emit = defineEmits<{
  (e: 'action', value: string): void
  (e: 'error', value: string): void
}>()

const comment = ref('')

const {mutate: disputeAllocation, onDone} = useMutation(AllocationPreviewDispute, {
  errorPolicy: "all"
})

function dispute() {
  disputeAllocation({
    id: props.id,
    timestamp: props.timestamp,
    signature: props.signature,
    comment: comment.value
  })
}

onDone((result) => {
  if (result.data.allocationPreviewDispute) {
    emit('action', 'disputed')
  }

  if (result.errors) {
    emit('error', result.errors[0].message)
  }
})

</script>

<template>
  <div>
    <p class="text-center mb-4">We are sorry to hear that there is something wrong with your order.</p>
    <p class="text-center mb-4">Please provide a description of the problem below, and we will inform the
      merchant.</p>
    <p class="text-center mb-8">In the meantime, the funds will be locked and the order will be placed on hold until
      the issue has been
      resolved.</p>
    <form @submit.prevent="dispute">
      <textarea
          class="mb-8 block w-full rounded-xl bg-gray-100 border-gray-300 focus:bg-white focus:ring-0"
          rows="5" v-model="comment" required></textarea>
      <div class="flex text-center text-lg my-4">
        <div class="flex-1">
          <a
              class="bg-gray-600 hover:bg-gray-800 text-white font-bold items-center px-4 py-2 inline-flex rounded-md cursor-pointer"
              @click.prevent="emit('action', 'details')">
            <span>Back</span>
          </a>
        </div>
        <div class="flex-1">
          <button
              class="bg-crimson-600 hover:bg-crimson-800 text-white font-bold items-center px-4 py-2 inline-flex rounded-md">
            <span>Submit</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>