<script setup lang="ts">
import {onMounted, ref} from "vue";

const props = defineProps<{
  value: number
}>()

const countdown = ref(10)
const date = new Date()
const day = date.toLocaleString('default', { day: '2-digit' })
const month = date.toLocaleString('default', { month: 'long' })
const year = date.getFullYear()

const emit = defineEmits<{
  (e: 'result', value: string): void
}>()

onMounted(() => {
  const counter = setInterval(() => {
    countdown.value -= 1

    if (countdown.value <= 0) {
      clearInterval(counter);
      setResult('Success')
    }
  }, 1000)
})

function setResult(value: string) {
  emit('result', value)
}
</script>

<template>
  <div class="p-4 h-full md:max-w-3xl md:m-auto">
    <h3 class="uppercase font-bold text-xl text-center mb-4">Thank you for your deposit</h3>

    <div class="text-center w-2/3 m-auto">We will let you and the merchant know as soon as the funds have cleared.</div>

    <div class="grid grid-cols-6 gap-2 border-t border-b my-4 py-4">
      <div class="font-bold col-start-2">Date:</div>
      <div class="col-span-3 text-right">{{ day }} {{ month }} {{ year }}</div>

      <div class="font-bold col-start-2">Type:</div>
      <div class="col-span-3 text-right">EFT</div>

      <div class="font-bold col-start-2">Total:</div>
      <div class="col-span-3 text-right">{{ value.toLocaleString('en-ZA', {style: 'currency', currency: 'ZAR'}).replace(',', '.') }}</div>

      <div class="font-bold col-start-2">State:</div>
      <div class="col-span-3 text-right">Funds Deposited</div>
    </div>

    <div class="mb-4 text-center">You can close this window to return to the store.</div>
    <div class="mb-4 text-center">Alternatively, this window will automatically close in {{ countdown }} seconds.</div>

    <div class="text-center uppercase bg-crimson-500 text-white p-2 rounded rounded-md cursor-pointer"
         @click="setResult('Success')">
      Close
    </div>
  </div>
</template>
