<script setup lang="ts">
import {onMounted, ref} from "vue";

const props = defineProps<{
  link: string
}>()

const isPending = ref(false)

const emit = defineEmits<{
  (e: 'refresh', value: boolean): void
}>()

onMounted(() => {
  const counter = setInterval(() => {
    emit('refresh', true)
  }, 30000)
})
</script>

<template>
  <div>
    <div class="px-4 py-8 text-sm text-center text-white bg-pjn h-full">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 129 64" class="h-24 mb-8 m-auto">
        <path fill="#fff"
              d="M4.84 60.027H2.75v3.759H0V51.259h5.107c2.982 0 4.786 1.777 4.786 4.33v.036c0 2.902-2.25 4.402-5.054 4.402Zm2.267-4.384c0-1.232-.857-1.893-2.232-1.893H2.75v3.83h2.18c1.375 0 2.178-.821 2.178-1.892v-.045Zm12.876 8.134-1.143-2.813h-5.286l-1.143 2.813H9.607l5.358-12.616H17.5l5.358 12.616h-2.875Zm-3.786-9.295-1.661 4.063h3.322l-1.661-4.063Zm12.902 4.304v4.991h-2.75v-4.938l-4.804-7.58h3.214l2.983 5.027 3.035-5.027h3.125L29.1 58.786Zm8.536 5.179c-1.875 0-3.072-.93-3.858-2.224l1.018-.875c.768 1.16 1.554 1.786 2.858 1.786 1.41 0 2.446-1.018 2.446-3v-8.393h1.429v8.375c0 2.875-1.661 4.33-3.893 4.33Zm17.455-5.536c0 3.651-2.089 5.544-5.214 5.544-3.09 0-5.197-1.892-5.197-5.437V51.25h1.411v7.197c0 2.705 1.429 4.223 3.822 4.223 2.303 0 3.767-1.393 3.767-4.134V51.25h1.411v7.179Zm7.822-1.589c2.786.606 4.071 1.633 4.071 3.562 0 2.16-1.785 3.562-4.268 3.562-1.982 0-3.607-.66-5.107-2l.875-1.035c1.304 1.178 2.554 1.768 4.286 1.768 1.679 0 2.786-.875 2.786-2.143 0-1.179-.625-1.849-3.25-2.393-2.875-.625-4.197-1.554-4.197-3.634 0-2.01 1.732-3.438 4.107-3.438 1.822 0 3.125.518 4.393 1.536l-.821 1.09c-1.16-.947-2.322-1.358-3.607-1.358-1.625 0-2.661.893-2.661 2.045 0 1.187.643 1.848 3.393 2.437Zm11.411 6.937h-1.429V52.562h-4.196V51.26h9.821v1.303h-4.196v11.215Zm16.241 0-6.054-7.965v7.965h-2.723V51.259h2.536l5.857 7.714V51.26h2.714v12.518h-2.33Zm11.411.223c-3.857 0-6.625-2.875-6.625-6.446v-.036c0-3.563 2.803-6.473 6.661-6.473 3.857 0 6.625 2.875 6.625 6.437v.036c0 3.563-2.804 6.482-6.661 6.482Zm3.786-6.482c0-2.152-1.572-3.938-3.786-3.938s-3.75 1.75-3.75 3.902v.036c0 2.152 1.571 3.938 3.786 3.938 2.214 0 3.75-1.75 3.75-3.902v-.036Zm18.348 6.357h-2.393l-2.821-8.196-2.822 8.196h-2.392l-4.268-12.616h2.946l2.589 8.482 2.804-8.518h2.357l2.804 8.518 2.589-8.482h2.875l-4.268 12.616Z"/>
        <path fill="#FF5C75"
              d="m108.957 32.303-4.848 3.179c-6.375-9.768-16.161-16.465-27.563-18.858-11.402-2.393-23.054-.196-32.804 6.188-8.09 5.303-14.072 12.955-17.233 21.938h-6.107c3.375-11.01 10.429-20.411 20.17-26.795 11.045-7.233 24.242-9.724 37.162-7.01 12.91 2.706 24 10.287 31.223 21.358Z"/>
        <path fill="#009EDD"
              d="M79.751 1.285 78.555 6.99c-13.974-2.928-28.25-.24-40.197 7.59-10.893 7.134-18.67 17.75-22.17 30.17H10.16c3.66-14.465 12.464-26.84 25.01-35.054 13.25-8.68 29.08-11.67 44.58-8.411Z"/>
        <path fill="#6BE1E7"
              d="M32.983 6.347c-13.67 8.947-23.188 22.536-26.93 38.394H.108C3.973 26.857 14.51 11.508 29.813 1.49l3.17 4.856Z"/>
      </svg>
      <p class="mb-4">Buy Now Pay Later! Get your goods now by paying 3 equal interest-free instalments.</p>
      <p class="mb-4">We have identified you as a new PayJustNow customer.</p>
      <p class="mb-4">Click the button below to get started.</p>
      <p class="mb-4">You will then be directed to a new window where you will kick-off your application. Once your
        first instalment has been made, you will be redirected back to the store.</p>

      <p class="mb-8 font-bold">Please refresh this page once you successfully completed the process with PayJustNow.</p>

      <div v-if="isPending" class="mb-8 my-auto">
        <!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL -->
        <svg class="h-16 w-16 m-auto" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
          <g fill="none" fill-rule="evenodd" transform="translate(1 1)" stroke-width="2">
            <circle cx="22" cy="22" r="6" stroke-opacity="0">
              <animate attributeName="r"
                       begin="1.5s" dur="3s"
                       values="6;22"
                       calcMode="linear"
                       repeatCount="indefinite"/>
              <animate attributeName="stroke-opacity"
                       begin="1.5s" dur="3s"
                       values="1;0" calcMode="linear"
                       repeatCount="indefinite"/>
              <animate attributeName="stroke-width"
                       begin="1.5s" dur="3s"
                       values="2;0" calcMode="linear"
                       repeatCount="indefinite"/>
            </circle>
            <circle cx="22" cy="22" r="6" stroke-opacity="0">
              <animate attributeName="r"
                       begin="3s" dur="3s"
                       values="6;22"
                       calcMode="linear"
                       repeatCount="indefinite"/>
              <animate attributeName="stroke-opacity"
                       begin="3s" dur="3s"
                       values="1;0" calcMode="linear"
                       repeatCount="indefinite"/>
              <animate attributeName="stroke-width"
                       begin="3s" dur="3s"
                       values="2;0" calcMode="linear"
                       repeatCount="indefinite"/>
            </circle>
            <circle cx="22" cy="22" r="8">
              <animate attributeName="r"
                       begin="0s" dur="1.5s"
                       values="6;1;2;3;4;5;6"
                       calcMode="linear"
                       repeatCount="indefinite"/>
            </circle>
          </g>
        </svg>
      </div>

      <a :href="link" target="_blank" class="p-4 bg-white text-bold text-neutral-800 rounded-md cursor-pointer"
         @click="isPending = true">Set up PayJustNow</a>
    </div>

  </div>
</template>

<style>
.bg-pjn {
  background-color: #14204a;
}
</style>
