<script setup lang="ts">
import {ref, reactive, onMounted} from "vue";
import {useRoute} from "vue-router";
import {useMutation} from "@vue/apollo-composable";
import {useVuelidate} from '@vuelidate/core'
import {required, helpers, numeric} from '@vuelidate/validators'
import {validCardNumber} from '../../composables/validCardNumber'
import CheckoutUCountProcess from "../../graphql/mutation/CheckoutUCountProcess.gql";

const route = useRoute();

const props = defineProps<{
  value: number
}>()

const emit = defineEmits<{
  (e: 'result', value: string): void
  (e: 'toggleForm', value: boolean): void
  (e: 'refresh', value: boolean): void
}>()

onMounted(() => {
  window.addEventListener('message', (e: any) => {
    if (e.origin === import.meta.env.VITE_PAYMENT_PAGE_URL && e.data.type === 'tradesafePaymentNotification') {
      if (e.data.status !== 'Success') {
        errorMessage.value = e.data.message
        toggleForm.value = true
      }
    }
  })
})

const {mutate: processUCount, loading, error, onDone} = useMutation(CheckoutUCountProcess, {
  errorPolicy: "all"
})

const toggleForm = ref(true)
const currentYear = new Date().getFullYear()

const errorMessage = ref('')

const rules = {
  amount: {required, numeric},
  number: {
    required,
    validCardNumber: helpers.withMessage('Invalid card number', validCardNumber)
  },
  year: {required},
  month: {required},
  cvv: {required, numeric},
  name: {required},
  save: {},
  description: {},
}

const state = reactive({
  'amount': props.value,
  'number': null,
  'year': '',
  'month': '',
  'cvv': null,
  'name': null,
  'save': false,
  'description': null,
})

const v$ = useVuelidate(rules, state)

async function processCard(e: Event) {
  e.preventDefault();

  const valid = await v$.value.$validate()

  if (valid) {
    await processUCount({
      data: route.params.data,
      amount: state.amount,
      number: state.number,
      month: state.month,
      year: state.year,
      cvv: state.cvv,
      name: state.name,
    })
  }
}

onDone((result) => {
  if (result?.data?.checkoutCardCreate) {
    // TODO: waiting for new gateway process
  }
})
</script>

<template>
  <form v-if="toggleForm" @submit="processCard">
    <div>
      <div class="grid grid-cols-3 gap-2">
        <div v-if="errorMessage"
             class="col-span-3 border border-red-600 bg-red-50 text-red-600 rounded rounded-xl text-center p-2 mb-4">{{
            errorMessage
          }}
        </div>

        <div class="col-span-3 text-center">
          <div class="m-auto h-12 mb-4">
            <img src="/src/assets/ucount.svg" class="inline h-full"/>
          </div>
        </div>

        <div class="col-span-1 relative">
          <label class="sr-only" for="number">Amount</label>
          <div class="relative">
            <input name="amount" type="number" step="0.01" :max="value" required v-model="state.amount"
                   @blur="v$.amount.$touch"
                   class="w-full mt-2 mb-4 p-2 pl-8 rounded border border-neutral-300 focus:border-neutral-300 focus:ring-0 focus:outline-crimson-500">

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="absolute top-5 left-2 w-5 h-5 text-neutral-500">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"/>
            </svg>
          </div>

          <div class="text-crimson-500" v-for="error of v$.amount.$errors" :key="error.$uid">
            <div class="error-msg text-sm">{{ error.$message }}</div>
          </div>
        </div>

        <div class="col-span-2 relative text-right">
          <label class="sr-only" for="number">Remaining</label>

          <div
              class="w-full mt-2 mb-4 py-2">
            <span class="font-bold">Remaining:</span> {{ (value - state.amount).toLocaleString('en-ZA', {style: 'currency', currency: 'ZAR'}).replace(',', '.') }}
          </div>
        </div>

        <div class="col-span-3 relative">
          <label class="sr-only" for="number">Card Number</label>
          <input name="number" type="text" maxlength="19" required v-model="state.number" @blur="v$.number.$touch"
                 placeholder="Card Number"
                 class="w-full mt-2 mb-4 p-2 pl-8 rounded border border-neutral-300 focus:border-neutral-300 focus:ring-0 focus:outline-crimson-500">

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" class="absolute top-5 left-2 w-5 h-5 text-neutral-500">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"/>
          </svg>

          <div class="text-crimson-500" v-for="error of v$.number.$errors" :key="error.$uid">
            <div class="error-msg text-sm">{{ error.$message }}</div>
          </div>
        </div>

        <div class="col-span-1 relative">
          <label class="sr-only" for="month">Month</label>
          <select name="month" required v-model="state.month" @blur="v$.month.$touch"
                  class="w-full mt-2 mb-4 p-2 pl-8 rounded border border-neutral-300 focus:border-neutral-300 focus:ring-0 focus:outline-crimson-500 invalid:text-neutral-500">
            <option value="" disabled selected>Month</option>
            <option v-for="n in 12" :key="n" :value="n">{{ n }}</option>
          </select>

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" class="absolute top-5 left-2 w-5 h-5 text-neutral-500">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"/>
          </svg>

          <div class="text-crimson-500" v-for="error of v$.month.$errors" :key="error.$uid">
            <div class="error-msg text-sm">{{ error.$message }}</div>
          </div>
        </div>

        <div class="col-span-1 relative">
          <label class="sr-only" for="year">Year</label>
          <select name="year" required v-model="state.year" @blur="v$.year.$touch"
                  class="w-full mt-2 mb-4 p-2 pl-8 rounded border border-neutral-300 focus:border-neutral-300 focus:ring-0 focus:outline-crimson-500 invalid:text-neutral-500">
            <option value="" disabled selected>Year</option>
            <option :value="currentYear">{{ currentYear }}</option>
            <option v-for="n in 10" :key="n" :value="currentYear + n">{{ currentYear + n }}</option>
          </select>

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" class="absolute top-5 left-2 w-5 h-5 text-neutral-500">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"/>
          </svg>

          <div class="text-crimson-500" v-for="error of v$.year.$errors" :key="error.$uid">
            <div class="error-msg text-sm">{{ error.$message }}</div>
          </div>
        </div>

        <div class="col-span-1 relative">
          <label class="sr-only" for="cvv">CVV</label>
          <input name="cvv" type="text" minlength="3" maxlength="3" v-model="state.cvv" @blur="v$.cvv.$touch"
                 placeholder="CVV"
                 class="w-full mt-2 mb-4 p-2 pl-8 rounded border border-neutral-300 focus:border-neutral-300 focus:ring-0 focus:outline-crimson-500">

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" class="absolute top-5 left-2 w-5 h-5 text-neutral-500">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
          </svg>

          <div class="text-crimson-500" v-for="error of v$.cvv.$errors" :key="error.$uid">
            <div class="error-msg text-sm">{{ error.$message }}</div>
          </div>
        </div>

        <div class="col-span-3 relative">
          <label class="sr-only" for="number">Card Holder Name</label>
          <input name="number" type="text" required v-model="state.name" @blur="v$.name.$touch"
                 placeholder="Card Holder Name"
                 class="w-full mt-2 mb-4 p-2 pl-8 rounded border border-neutral-300 focus:border-neutral-300 focus:ring-0 focus:outline-crimson-500">

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" class="absolute top-5 left-2 w-5 h-5 text-neutral-500">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"/>
          </svg>

          <div class="text-crimson-500" v-for="error of v$.name.$errors" :key="error.$uid">
            <div class="error-msg text-sm">{{ error.$message }}</div>
          </div>
        </div>

        <div v-if="state.save" class="col-span-3 relative">
          <label class="sr-only" for="number">Card Description</label>
          <input name="number" type="text" required v-model="state.description" @blur="v$.description.$touch"
                 placeholder="Card Description"
                 :required="state.save"
                 class="w-full mt-2 mb-4 p-2 pl-8 rounded border border-neutral-300 focus:border-neutral-300 focus:ring-0 focus:outline-crimson-500">

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" class="absolute top-5 left-2 w-5 h-5 text-neutral-500">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z"/>
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6z"/>
          </svg>

          <div class="text-crimson-500" v-for="error of v$.description.$errors" :key="error.$uid">
            <div class="error-msg text-sm">{{ error.$message }}</div>
          </div>
        </div>

        <div class="col-span-3">
          <img src="/src/assets/pci.svg" class="w-32 m-auto"/>
        </div>
      </div>
    </div>

    <div v-if="loading"
         class="w-full col-span-3 text-center uppercase bg-neutral-400 text-white p-2 rounded rounded-md cursor-progress">
      Processing Card...
    </div>

    <button v-else type="submit"
            class="w-full col-span-3 text-center uppercase bg-crimson-500 text-white p-2 rounded rounded-md cursor-pointer">
      Pay {{ state.amount.toLocaleString('en-ZA', {style: 'currency', currency: 'ZAR'}).replace(',', '.') }}
    </button>
  </form>
</template>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input[name=amount]::-webkit-outer-spin-button,
input[name=amount]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[name=amount] {
  -moz-appearance: textfield;
}
</style>
