export const validCardNumber = (cardNumber: string) => {
    cardNumber = cardNumber.replaceAll(' ', '')

    let pattern = new RegExp("^[0-9]{16}$")
    let regexTest = pattern.test(cardNumber)

    if (!regexTest) {
        return false
    }

    let numbers = cardNumber.slice(0, 16).split('')
    let checksum = 0

    numbers.forEach(function (value, index) {
        let digit = parseInt(value)

        if (index % 2 === 0) {
            digit = (digit * 2)

            if (digit > 9) {
                digit -= 9
            }
        }

        checksum += digit
    })

    return checksum % 10 === 0;
}