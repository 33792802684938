<script setup lang="ts">
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useQuery} from "@vue/apollo-composable";
import Spinner from "../Spinner.vue";
import UCountProcess from "./Process.vue";

const route = useRoute();

const props = defineProps<{
  data: string,
  value: number,
}>()

const emit = defineEmits<{
  (e: 'result', value: string): void
}>()
</script>

<template>
  <div class="p-4 h-full">
    <UCountProcess :value="value"></UCountProcess>
  </div>
</template>