import {createApp} from 'vue'
import {createPinia} from "pinia";
import {DefaultApolloClient} from '@vue/apollo-composable'
import {ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client/core'
import router from './router'
import * as Sentry from "@sentry/vue";
import {BrowserTracing} from "@sentry/tracing";
import App from './App.vue'
import './tailwind.css'

const pinia = createPinia()
const app = createApp(App)

const getCookieValue = (name: any) => (
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
)

let token = getCookieValue('XSRF-TOKEN')

if (token === '') {
    try {
        let xmlHttp = new XMLHttpRequest()
        xmlHttp.open('GET', import.meta.env.VITE_GRAPHQL_XSRF_URL, false) // false for synchronous request
        xmlHttp.send(null)
        const xsrf = JSON.parse(xmlHttp.responseText)
        token = xsrf.token ?? ''
    } catch (e) {
        console.log(e)
    }
}

const httpLink = createHttpLink({
    credentials: 'include',
    uri: import.meta.env.VITE_GRAPHQL_URL,
    headers: {
        'X-CSRF-TOKEN': token,
    },
})

const cache = new InMemoryCache({
    addTypename: false
})

const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
})

Sentry.init({
    app,
    dsn: "https://71cc660a3471485e9b8fd902a79eaae5@o344503.ingest.sentry.io/6739872",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "pay-dev.tradesafe.dev", "pay-test.tradesafe.dev", "pay-sit.tradesafe.dev", "pay.tradesafe.co.za", /^\//],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: import.meta.env.VITE_SENTRY_SAMPLE_RATE,
    environment: import.meta.env.MODE,
});

app.provide(DefaultApolloClient, apolloClient)
    .use(pinia)
    .use(router)
    .mount('#app')