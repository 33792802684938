<script setup lang="ts">
import {onMounted, ref} from "vue";
import {cardType} from "../../card-type";

const props = defineProps<{
  card: any,
}>()

const logo = ref()
const cardBack = ref('from-neutral-400 to-neutral-400 via-neutral-200')

onMounted(() => {
  logo.value = cardLogo(props.card?.number)
})

function formatNumber(number: any) {
  let value = String(number).match(/([0-9*]{4})/g)

  if (value) {
    return value.join(" ")
  }

  return number
}

function cardLogo(number: any) {
  let type = cardType(number.replaceAll(' ', ''))

  if (type) {
    return new URL(`/src/assets/${type}.svg`, import.meta.url).href
  }

  return null
}

</script>

<template>
  <div v-if="card"
       class="text-sm font-mono rounded-md p-4 grid grid-cols-2 mb-8 max-w-xs m-auto bg-gradient-to-tl shadow-md shadow-neutral-400"
       :class="cardBack">
    <div class="font-sans">{{ card.description }}</div>
    <div v-if="logo">
      <img :src="logo" class="h-5 ml-auto"/>
    </div>
    <div class="col-span-2 pb-4 pt-8 text-center font-bold">{{ formatNumber(card.number) }}</div>
    <div class="">
      <div class="text-xs">Card Holder</div>
      <div class="font-bold">{{ card.name }}</div>
    </div>
    <div class="text-right">
      <div class="text-xs">Expires</div>
      <div class="font-bold">{{ String(card.month).padStart(2, '0') }}/{{ String(card.year).slice(-2) }}</div>
    </div>
  </div>
</template>
