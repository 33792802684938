<script setup lang="ts">
import {shallowRef} from 'vue';
import {useRouter} from "vue-router";
import Details from "../components/Details.vue"

const router = useRouter();
const isDetailsOpen = shallowRef<boolean>(false)

defineProps<{
  logo: string,
  name: string,
  title: string,
  description: string,
  total: number,
  deposit: number,
  items: object,
  fee: number,
  back: boolean,
}>()

function toggleDetails() {
  isDetailsOpen.value = !isDetailsOpen.value
}

</script>

<template>
  <div class="fixed w-full top-0 z-40">
    <div
        class="lg:hidden bg-white text-neutral-700 border border-b-neutral-200 sticky top-0 h-16 flex items-center justify-center z-30">
      <div v-if="back" class="flex-none h-16 w-16 p-4 cursor-pointer" @click="router.go(-1)">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18"/>
        </svg>
      </div>

      <div class="flex-auto">
        <img :alt="name" :src="logo" class="h-16 p-4 object-contain" :class="!!back ? 'pl-0' : ''" v-if="logo" />
      </div>

      <div class="flex-none align-middle text-lg font-medium" @click="toggleDetails">
        <div>{{ total.toLocaleString('en-ZA', {style: 'currency', currency: 'ZAR'}).replace(',', '.') }}</div>
      </div>

      <div class="flex-none h-16 w-16 p-4" @click="toggleDetails">
        <svg v-if="isDetailsOpen === false" xmlns="http://www.w3.org/2000/svg" fill="none"
             viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
        <svg v-if="isDetailsOpen === true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
      </div>
    </div>

    <div id="details"
         class="lg:hidden transform top-0 left-0 right-0 h-full pt-16 fixed overflow-auto ease-in-out transition-all duration-300 z-10 backdrop-blur-sm bg-black bg-opacity-30"
         :class="isDetailsOpen ? 'translate-y-0' : '-translate-y-full'">
      <Details :items="items" :total="total" :deposit="deposit" :title="title" :description="description" :fee="fee"></Details>
    </div>

    <div class="hidden lg:block bg-crimson-500">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
           preserveAspectRatio="xMidYMid meet"
           viewBox="0.0041371551656244065 0.0009068930194651159 320 256.94000000000005"
           class="stroke-0 stroke-white fill-white h-16 py-4 m-auto"
      >
        <defs>
          <path
              d="M181.42 191.74C178.88 191.74 176.33 190.77 174.39 188.83C166.9 181.34 106.96 121.4 99.47 113.91C95.58 110.02 95.58 103.73 99.46 99.84C103.35 95.96 109.64 95.96 113.53 99.84C121.02 107.34 180.96 167.28 188.45 174.77C192.33 178.65 192.33 184.95 188.45 188.83C186.51 190.77 183.97 191.74 181.42 191.74"
              id="cgKBmtKmT"></path>
          <path
              d="M213.14 160.02C210.6 160.02 208.05 159.05 206.11 157.11C198.62 149.62 138.68 89.68 131.19 82.18C127.3 78.3 127.3 72.01 131.19 68.12C135.07 64.24 141.36 64.24 145.25 68.12C152.74 75.62 212.68 135.56 220.17 143.05C224.06 146.93 224.06 153.23 220.17 157.11C218.23 159.05 215.69 160.02 213.14 160.02"
              id="e4v7qBnAWP"></path>
          <path
              d="M77.76 214.48C75.22 214.48 72.67 213.51 70.73 211.56C65.24 206.08 21.34 162.17 15.85 156.69C-12.54 128.3 3.44 97.32 15.85 84.9C22.76 78 78 22.76 84.9 15.85C113.29 -12.54 144.27 3.44 156.69 15.85C166.18 25.34 242.07 101.24 251.56 110.72C255.44 114.61 255.44 120.9 251.56 124.78C247.68 128.66 241.38 128.66 237.5 124.78C228.01 115.3 152.11 39.4 142.63 29.91C142.61 29.89 136.53 24.01 127.93 21.31C117.5 18.04 108.03 20.85 98.96 29.91C92.06 36.82 36.82 92.06 29.91 98.96C29.89 98.98 24.02 105.06 21.32 113.66C18.04 124.09 20.85 133.57 29.91 142.63C35.4 148.11 79.3 192.02 84.79 197.5C88.67 201.39 88.67 207.68 84.79 211.56C82.85 213.51 80.3 214.48 77.76 214.48"
              id="fTEx49Nsi"></path>
          <path
              d="M200.51 256.94C194.86 256.94 189.71 255.86 185.32 254.39C172.64 250.17 164.31 242.08 163.4 241.17C153.91 231.68 78.01 155.78 68.53 146.3C64.64 142.41 64.64 136.12 68.53 132.24C72.41 128.35 78.7 128.35 82.59 132.24C92.07 141.72 167.97 217.62 177.46 227.11C177.48 227.13 183.56 233.01 192.15 235.71C202.59 238.98 212.06 236.17 221.12 227.11C228.03 220.2 283.27 164.96 290.17 158.06C290.19 158.04 296.07 151.96 298.77 143.36C302.04 132.93 299.23 123.45 290.17 114.4C284.68 108.91 240.78 65 235.3 59.52C231.41 55.63 231.41 49.34 235.3 45.46C239.18 41.57 245.47 41.57 249.36 45.46C254.84 50.94 298.75 94.85 304.23 100.33C322.73 118.83 321.64 137.66 317.46 150.19C313.23 162.88 305.14 171.21 304.23 172.12C297.33 179.02 242.09 234.26 235.18 241.17C223.16 253.2 210.99 256.94 200.51 256.94"
              id="c62Nsn2dxY"></path>
        </defs>
        <g>
          <g>
            <g>
              <use xlink:href="#cgKBmtKmT" opacity="1" fill-opacity="1"></use>
              <g>
                <use xlink:href="#cgKBmtKmT" opacity="1" fill-opacity="0" stroke-width="1"
                     stroke-opacity="0"></use>
              </g>
            </g>
            <g>
              <use xlink:href="#e4v7qBnAWP" opacity="1" fill-opacity="1"></use>
              <g>
                <use xlink:href="#e4v7qBnAWP" opacity="1" fill-opacity="0" stroke-width="1"
                     stroke-opacity="0"></use>
              </g>
            </g>
            <g>
              <use xlink:href="#fTEx49Nsi" opacity="1" fill-opacity="1"></use>
              <g>
                <use xlink:href="#fTEx49Nsi" opacity="1" fill-opacity="0" stroke-width="1"
                     stroke-opacity="0"></use>
              </g>
            </g>
            <g>
              <use xlink:href="#c62Nsn2dxY" opacity="1" fill-opacity="1"></use>
              <g>
                <use xlink:href="#c62Nsn2dxY" opacity="1" fill-opacity="0" stroke-width="1"
                     stroke-opacity="0"></use>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>
