<script setup lang="ts">
import {ref} from "vue";
import Confirm from "./Confirm.vue";
import CardFace from "./CardFace.vue";

const activeCard = ref()

defineProps<{
  value: number
  list: object | null
}>()

const emit = defineEmits<{
  (e: 'result', value: string): void
  (e: 'toggleAddButton', value: boolean): void
  (e: 'refresh', value: boolean): void
}>()

function setActiveCard(card: any) {
  emit('toggleAddButton', true)
  activeCard.value = card
}

function refresh(value: boolean) {
  emit('refresh', value)
}

function paymentResult(value: string) {
  emit('result', value)
}
</script>

<template>
  <div v-if="list && activeCard == null" class="">
    <card-face v-for="card in list" :card="card" @click="setActiveCard(card)" class="cursor-pointer"></card-face>
  </div>

  <Confirm v-if="activeCard" :card="activeCard" :value="value" @refresh="refresh" @result="paymentResult"></Confirm>
</template>